import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faChartLine,
  faCode,
  faFileCode,
  faHeart,
  faLaptopCode,
  faMapMarkerAlt,
  faPaperPlane,
  faPhone,
  faTasks,
  faTools
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

/* add any additional icon to the library */
library.add(
  fab, 
  faChartLine,
  faCode,
  faFileCode,
  faHeart,
  faLaptopCode, 
  faMapMarkerAlt, 
  faPaperPlane,
  faPhone, 
  faTasks,
  faTools
);

export type IconProps = FontAwesomeIconProps['icon'] | String;

const Icon: React.FC<FontAwesomeIconProps> = ({ ...props }) => {
  if ((props.icon as String).indexOf(' ') > 0) {
    const iconArray = (props.icon as String).split(' ');
    if (iconArray.length == 2) {
      props.icon = iconArray as FontAwesomeIconProps['icon'];
    }
  }
  
  return <FontAwesomeIcon {...props} />;
}

export default Icon;
