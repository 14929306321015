import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/ui/Container';
import Icon, { IconProps } from 'components/ui/Icon';
import GatsbyLogo from 'assets/svg/Gatsby-Monogram.inline.svg';

import * as Styled from './styles';
import styled from 'styled-components';

interface SocialLink {
  node: {
    id: string;
    frontmatter: {
      title: string;
      link: string;
      icon: IconProps;
    };
  };
}

const Footer: React.FC = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { category: { eq: "social" } } }, sort: { fields: fileAbsolutePath }) {
        edges {
          node {
            id
            frontmatter {
              title
              link
              icon
            }
          }
        }
      }
    }
  `);

  const links: SocialLink[] = allMarkdownRemark.edges;

  return (
    <Styled.Footer>
      <Container>
        <Styled.Links>
          {links.map((item) => {
            const {
              id,
              frontmatter: { title, link, icon }
            } = item.node;

            return (
              <Styled.Link key={id} href={link} rel="noreferrer noopener" target="_blank">
                <Icon icon={icon} /> {title}
              </Styled.Link>
            );
          })}
          <Styled.Link href="https://www.gatsbyjs.com/" rel="noreferrer noopener" target="_blank">
            Built with <Icon icon="heart" /> and <GatsbyLogo class="fill-current" />
          </Styled.Link>
        </Styled.Links>
      </Container>
    </Styled.Footer>
  );
};

export default Footer;
